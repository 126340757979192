import axios from "axios";
console.log("API_BASE_URL", window._env_);
export const API_BASE_URL = window._env_.REACT_APP_SIGNO_API_URL;
const axiosInstance = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        "Content-Type": "application/json",
    },
});
// Interceptor para agregar el token automáticamente
axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("token"); // O cualquier otra forma de obtener el token
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});
export default axiosInstance;
