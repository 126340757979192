var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// authUtils.ts (en la carpeta utils)
import jwtDecode from 'jwt-decode';
import axios from 'axios';
export const isTokenExpired = (token) => {
    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        return decoded.exp < currentTime;
    }
    catch (error) {
        return true; // If decoding fails, assume expired
    }
};
export const renewToken = (refreshToken) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield axios.post(`${process.env.REACT_APP_SIGNO_API_URL}/Auth/Refresh`, {
            refreshToken,
        });
        const { accessToken, refreshToken: newRefreshToken } = response.data;
        // Update tokens in localStorage
        localStorage.setItem('token', accessToken);
        localStorage.setItem('refreshToken', newRefreshToken);
        return { accessToken, newRefreshToken };
    }
    catch (error) {
        console.error('Error renewing token:', error);
        throw error;
    }
});
export const scheduleTokenRenewal = (token, refreshToken) => {
    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        const timeToExpire = decoded.exp - currentTime;
        // Renew token 1 minute before it expires
        if (timeToExpire > 60) {
            setTimeout(() => __awaiter(void 0, void 0, void 0, function* () {
                try {
                    yield renewToken(refreshToken);
                }
                catch (_a) {
                    console.log('Failed to renew token. Redirecting to login.');
                    localStorage.clear();
                    window.location.href = '/login';
                }
            }), (timeToExpire - 60) * 1000);
        }
    }
    catch (error) {
        console.error('Error scheduling token renewal:', error);
    }
};
