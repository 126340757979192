var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useState, useEffect } from "react";
import { isTokenExpired, renewToken, scheduleTokenRenewal } from "../services/Auth/authService";
import { getToken } from "../services/userService";
export const useAuth = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);
    const login = ({ username, password }) => __awaiter(void 0, void 0, void 0, function* () {
        if (username === "admin" && password === "Conversia2025") {
            const token = yield getToken(); // Obtén un token válido desde tu backend
            const refreshToken = "mock-refresh-token"; // Sustituye con el refresh token generado
            localStorage.setItem("token", token);
            localStorage.setItem("refreshToken", refreshToken);
            scheduleTokenRenewal(token, refreshToken); // Configura la renovación del token
            setIsAuthenticated(true);
        }
        else {
            alert("Invalid credentials");
        }
    });
    const logout = () => {
        setIsAuthenticated(false);
        localStorage.removeItem("token");
        localStorage.removeItem("refreshToken");
    };
    useEffect(() => {
        const checkAuthentication = () => __awaiter(void 0, void 0, void 0, function* () {
            setLoading(true);
            const token = localStorage.getItem("token");
            const refreshToken = localStorage.getItem("refreshToken");
            if (token) {
                if (isTokenExpired(token)) {
                    // Token expirado: intenta renovarlo
                    if (refreshToken) {
                        try {
                            const { accessToken, newRefreshToken } = yield renewToken(refreshToken);
                            localStorage.setItem("token", accessToken);
                            localStorage.setItem("refreshToken", newRefreshToken);
                            setIsAuthenticated(true);
                            scheduleTokenRenewal(accessToken, newRefreshToken);
                        }
                        catch (error) {
                            console.error("Error renewing token:", error);
                            logout();
                        }
                    }
                    else {
                        logout();
                    }
                }
                else {
                    // Token válido
                    setIsAuthenticated(true);
                    scheduleTokenRenewal(token, refreshToken);
                }
            }
            else {
                logout(); // Sin token, no está autenticado
            }
            setLoading(false);
        });
        checkAuthentication();
    }, []);
    return { isAuthenticated, loading, login, logout };
};
