import { jsx as _jsx } from "react/jsx-runtime";
import { ThemeProvider } from "@mui/material";
import AppRoutes from "./Routes";
import { useAuth } from "./hooks/useAuth";
import { useDynamicTheme } from "./hooks/useTheme";
import Loader from "./components/Common/Loader/Loader";
function App() {
    const theme = useDynamicTheme();
    const { loading } = useAuth();
    if (loading) {
        return _jsx(Loader, {});
    }
    return (_jsx(ThemeProvider, Object.assign({ theme: theme }, { children: _jsx(AppRoutes, {}) })));
}
export default App;
