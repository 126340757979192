import { useEffect, useState } from 'react';
import { createTheme, darken } from '@mui/material';
export const useDynamicTheme = () => {
    const [theme, setTheme] = useState(createTheme());
    useEffect(() => {
        const updateTheme = () => {
            const primaryColor = getComputedStyle(document.documentElement)
                .getPropertyValue('--color-primary')
                .trim();
            const secondaryColor = getComputedStyle(document.documentElement)
                .getPropertyValue('--color-secondary')
                .trim();
            const mainFont = getComputedStyle(document.documentElement)
                .getPropertyValue('--main-font')
                .trim();
            const secondaryFont = getComputedStyle(document.documentElement)
                .getPropertyValue('--secondary-font')
                .trim();
            setTheme(createTheme({
                cssVariables: true,
                palette: {
                    primary: {
                        main: primaryColor || '#1976d2', // Fallback
                    },
                    secondary: {
                        main: secondaryColor || '#dc004e', // Fallback
                    },
                },
                customImages: {
                    logo: '/assets/images/Logo_Signo.png',
                },
                /*         breakpoints: {
                        values: {
                            xs: 0,
                            sm: 576,
                            md: 768,
                            lg: 992,
                            xl: 1200,
                            xxl: 1400,
                        },
                        }, */
                typography: {
                    body1: {
                        fontFamily: secondaryFont || 'Roboto, sans-serif',
                    },
                },
                components: {
                    MuiButton: {
                        styleOverrides: {
                            root: {
                                fontFamily: mainFont || 'Roboto, sans-serif',
                                fontSize: '16px',
                                fontWeight: '600',
                                minWidth: 160
                            },
                        },
                    },
                    MuiToggleButton: {
                        styleOverrides: {
                            root: {
                                fontFamily: secondaryFont || 'Roboto, sans-serif',
                                color: primaryColor,
                                '&.Mui-selected': {
                                    backgroundColor: secondaryColor,
                                    color: 'white',
                                    '&:hover': {
                                        backgroundColor: darken(secondaryColor, 0.2),
                                    },
                                },
                            },
                        },
                    },
                    MuiAccordion: {
                        styleOverrides: {
                            root: ({ theme }) => ({
                                color: theme.palette.secondary.main,
                                boxShadow: 'none',
                                border: 'none',
                                '&:before': { display: 'none' },
                            }),
                        },
                    },
                    MuiAccordionSummary: {
                        styleOverrides: {
                            root: () => ({
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                padding: 0,
                                '& .MuiAccordionSummary-content': {
                                    marginLeft: '16px !important',
                                },
                            }),
                        },
                    },
                },
            }));
        };
        updateTheme();
        const observer = new MutationObserver(updateTheme);
        observer.observe(document.documentElement, { attributes: true, attributeFilter: ['style'] });
        return () => observer.disconnect();
    }, []);
    return theme;
};
