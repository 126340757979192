var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Button, FormLabel, TextField } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getToken } from "../../services/userService";
import { useAuth } from "../../hooks/useAuth";
const Login = () => {
    const { login } = useAuth();
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({
        username: "",
        password: "",
    });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false); // Estado para el spinner
    const handleChange = (e) => {
        const { name, value } = e.target;
        setCredentials((prev) => (Object.assign(Object.assign({}, prev), { [name]: value })));
    };
    const validateForm = () => {
        if (!credentials.username.trim() || !credentials.password.trim()) {
            setError("Ambos campos son requeridos.");
            return false;
        }
        setError(null);
        return true;
    };
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true); // Mostrar spinner
            try {
                localStorage.setItem("username", credentials.username);
                const token = yield getToken();
                localStorage.setItem("token", token);
                yield login(credentials);
                navigate("/users");
            }
            catch (err) {
                setError("Ocurrió un error al procesar tu solicitud.");
            }
            finally {
                setLoading(false); // Ocultar spinner
            }
        }
    });
    return (_jsxs("div", Object.assign({ className: "content-login" }, { children: [_jsx("img", { className: "img-login", src: "/assets/images/logo-signo.svg", alt: "Logo Signo" }), _jsx("h1", Object.assign({ className: "login-title fw-700" }, { children: "BackOffice" })), _jsxs("form", Object.assign({ onSubmit: handleSubmit }, { children: [error && _jsx(Alert, Object.assign({ severity: "error" }, { children: error })), _jsxs("div", Object.assign({ className: "field" }, { children: [_jsx(FormLabel, Object.assign({ className: "d-block" }, { children: "Usuario" })), _jsx(TextField, { name: "username", fullWidth: true, variant: "outlined", size: "small", value: credentials.username, onChange: handleChange, placeholder: "Introduce tu usuario", required: true, "aria-label": "Nombre de usuario" })] })), _jsxs("div", Object.assign({ className: "field" }, { children: [_jsx(FormLabel, Object.assign({ className: "d-block" }, { children: "Contrase\u00F1a" })), _jsx(TextField, { name: "password", fullWidth: true, type: "password", variant: "outlined", size: "small", value: credentials.password, onChange: handleChange, placeholder: "Introduce tu contrase\u00F1a", required: true, "aria-label": "Contrase\u00F1a" })] })), _jsx("div", Object.assign({ className: "field" }, { children: _jsx(Button, Object.assign({ color: "secondary", className: "btn-login", type: "submit", variant: "contained", disabled: loading }, { children: "Entrar" })) }))] }))] })));
};
export default Login;
