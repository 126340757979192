import { create } from "zustand";
// Estado inicial y funciones con el tipo correcto
const useSearchStore = create((set) => ({
    companies: [],
    searchTerm: "",
    setCompanies: (companies) => set({ companies }),
    setSearchTerm: (term) => set({ searchTerm: term }),
    reset: () => set({ companies: [], searchTerm: "" })
}));
export default useSearchStore;
