import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import MainContainer from "../../components/Common/MainContainer/MainContainer";
const Dashboard = () => {
    const [username, setUsername] = useState(null);
    // Obtener el nombre del usuario del localStorage
    useEffect(() => {
        const storedUsername = localStorage.getItem("username");
        setUsername(storedUsername);
    }, []);
    return (_jsx(_Fragment, { children: _jsxs(MainContainer, Object.assign({ containerClass: "dashboard" }, { children: [_jsx("h1", Object.assign({ className: "mt-4 mb-3" }, { children: "Dashboard" })), _jsx("span", { children: "Welcome to the dashboard! Use the buttons below to navigate or logout." }), _jsx("div", Object.assign({ className: "image-grid" }, { children: _jsxs("div", Object.assign({ className: "row" }, { children: [_jsx("div", Object.assign({ className: "col-12 col-lg-3 centered-icon" }, { children: _jsxs(RouterLink, Object.assign({ to: "/users", style: { textDecoration: "none" } }, { children: [_jsx("i", Object.assign({ className: "bi bi-person" }, { children: "icono" })), _jsx("br", {}), _jsx("span", { children: "Gesti\u00F3n de Usuarios" })] })) })), _jsxs("div", Object.assign({ className: "col-12 col-lg-3 centered-icon disabled" }, { children: [_jsx(RouterLink, Object.assign({ to: "/users", style: { textDecoration: "none" } }, { children: _jsx("i", { className: "bi bi-graph-up-arrow" }) })), _jsx("br", {}), _jsx("span", { children: "Informes y an\u00E1lisis" })] })), _jsxs("div", Object.assign({ className: "col-12 col-lg-3 centered-icon disabled" }, { children: [_jsx(RouterLink, Object.assign({ to: "/users", style: { textDecoration: "none" } }, { children: _jsx("i", { className: "bi bi-tools" }) })), _jsx("br", {}), _jsx("span", { children: "Configuraci\u00F3n y Ajuste" })] })), _jsxs("div", Object.assign({ className: "col-12 col-lg-3 centered-icon disabled" }, { children: [_jsx(RouterLink, Object.assign({ to: "/users", style: { textDecoration: "none" } }, { children: _jsx("i", { className: "bi bi-headset" }) })), _jsx("br", {}), _jsx("span", { children: "SAT" })] }))] })) }))] })) }));
};
export default Dashboard;
