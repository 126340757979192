var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import userMock from "../mocks/user.json";
import axiosInstance from "../Interceptors/axiosConfig";
//console.log("API_BASE_URL", window._env_);
export const API_BASE_URL = window._env_.REACT_APP_SIGNO_API_URL;
export const getToken = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axios.post(`${API_BASE_URL}/Auth/Client`, {
        clientId: "4589E846-4EA8-4FB5-9106-89392E3A4C1D",
        clientSecret: "backoffice-secret",
    });
    localStorage.setItem("token", response.data.accessToken); // Guarda el token automáticamente
    console.log("Token", response.data.accessToken);
    return response.data.accessToken;
});
export const resetPassword = (username, password) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield axiosInstance.put("/User/reset-password", {
        username,
        password,
    });
    return response.data;
});
export const searchCompaniesByQuery = (documentId) => __awaiter(void 0, void 0, void 0, function* () {
    const mock = false;
    if (mock || window._env_.REACT_APP_MOCK === "true") {
        return userMock;
    }
    const response = yield axiosInstance.get(`/BackOffice/Company/${documentId}`);
    return response.data;
});
export const searchCompanyByNif = (nif) => __awaiter(void 0, void 0, void 0, function* () {
    console.log('NIF', nif);
    const mock = false;
    if (mock || window._env_.REACT_APP_MOCK === "true") {
        return userMock;
    }
    const response = yield axiosInstance.get(`/BackOffice/Company/${nif}`);
    return response.data[0];
});
