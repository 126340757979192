import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import useSearchStore from "../../../store/useSearchStore";
const Header = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [userName, setUserName] = useState(null);
    const clearState = () => {
        useSearchStore.getState().reset();
    };
    // Función para cerrar sesión
    const logoutUser = () => {
        //localStorage.clear(); // Limpia todos los datos del localStorage
        clearState();
        window.location.href = "/login"; // Redirige al login
    };
    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);
    useEffect(() => {
        setUserName(localStorage.getItem("username"));
    }, []);
    return (_jsx("div", Object.assign({ className: "header" }, { children: _jsxs("div", Object.assign({ className: "container-xl header-wrapper" }, { children: [_jsxs("div", Object.assign({ className: "header-info" }, { children: [_jsx("div", Object.assign({ className: "header-logo" }, { children: _jsx("img", { src: "/assets/images/logo-signo.svg", alt: "Logo Signo" }) })), _jsxs("div", Object.assign({ className: "header-user" }, { children: [_jsx("h1", Object.assign({ className: "main-title fw-700" }, { children: "BackOffice" })), _jsxs("span", Object.assign({ className: "header-greeting" }, { children: ["Hola ", userName] }))] }))] })), _jsxs("div", Object.assign({ className: "header-menu" }, { children: [_jsx(IconButton, Object.assign({ color: "primary", "aria-label": "Abrir men\u00FA", onClick: handleMenuOpen }, { children: _jsx(MenuIcon, {}) })), _jsx(Menu, Object.assign({ anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleMenuClose, anchorOrigin: { vertical: "bottom", horizontal: "right" }, transformOrigin: { vertical: "top", horizontal: "right" } }, { children: _jsxs(MenuItem, Object.assign({ onClick: logoutUser }, { children: [_jsx(LogoutIcon, { className: "me-2" }), " Logout"] })) }))] }))] })) })));
};
export default Header;
