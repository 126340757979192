import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, styled, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import { useState } from "react";
const UsersTable = ({ results, order, orderBy, onSort, onMenuOpen, isCebreado = false, isCondense = false, }) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const paginatedResults = results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    const StyledTableRow = styled(TableRow, {
        shouldForwardProp: (prop) => prop !== 'striped',
    })(({ theme, striped }) => ({
        backgroundColor: striped ? theme.palette.action.hover : 'transparent',
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));
    return (_jsxs(_Fragment, { children: [_jsx(TableContainer, Object.assign({ sx: {
                    boxShadow: "none",
                    border: "none",
                    maxHeight: 450
                } }, { children: _jsxs(Table, Object.assign({ stickyHeader: true, className: `users-table ${isCebreado ? 'cebreado' : ''}`, sx: {
                        borderCollapse: "collapse",
                    } }, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [[
                                        { key: "nif", label: "NIF" },
                                        { key: "email", label: "Email" },
                                        { key: "formCompletionDate", label: "Estado documentación" },
                                        { key: "bussinesName", label: "Persona de contacto" },
                                        { key: "phone", label: "Teléfono" },
                                        { key: "collectiveName", label: "Colectivo" },
                                    ].map((column) => (_jsx(TableCell, Object.assign({ sx: {
                                            fontWeight: "bold",
                                            borderBottom: "1px solid lightgray",
                                            borderTop: "none",
                                            borderLeft: "none",
                                            borderRight: "none", // Sin borde derecho
                                        } }, { children: _jsx(TableSortLabel, Object.assign({ active: orderBy === column.key, direction: orderBy === column.key ? order : "asc", onClick: () => onSort(column.key) }, { children: column.label })) }), column.key))), _jsx(TableCell, { sx: {
                                            borderBottom: "1px solid lightgray",
                                        } })] }) }), _jsx(TableBody, { children: paginatedResults.map((company, index) => (_jsxs(TableRow, Object.assign({ hover: true, sx: {
                                    borderBottom: "1px solid lightgray",
                                } }, { children: [_jsx(TableCell, { children: company.nif }), _jsx(TableCell, { children: company.email }), _jsx(TableCell, { children: !company.formCompletionDate ? (_jsxs(_Fragment, { children: [_jsx("i", { className: "bi bi-x-circle text-danger" }), "INCOMPLETA"] })) : (_jsxs(_Fragment, { children: [_jsx("i", { className: "bi bi-check-circle text-success" }), "COMPLETA"] })) }), _jsx(TableCell, { children: company.bussinesName }), _jsx(TableCell, { children: company.phone }), _jsx(TableCell, { children: company.collectiveName }), _jsx(TableCell, { children: _jsx(IconButton, Object.assign({ color: "primary", size: isCondense ? "small" : "medium", onClick: (event) => onMenuOpen(event, company), "aria-label": "more options" }, { children: _jsx(MoreVertIcon, { fontSize: "inherit" }) })) })] }), company.nif))) })] })) })), _jsx(TablePagination, { component: "div", count: results.length, page: page, onPageChange: handleChangePage, rowsPerPage: rowsPerPage, onRowsPerPageChange: handleChangeRowsPerPage, labelRowsPerPage: "Filas por p\u00E1gina" })] }));
};
export default UsersTable;
