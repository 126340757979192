import { jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter, RouterProvider, Navigate, Outlet } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import Users from "./pages/Users/Users";
import UserDetails from "./pages/UserDetails/UserDetails";
import { useAuth } from "./hooks/useAuth";
const AppRoutes = () => {
    const { isAuthenticated, loading } = useAuth();
    if (loading) {
        return _jsx("div", { children: "Loading..." }); // O un componente de Loader
    }
    const publicRoutes = [
        { path: "/", element: _jsx(Login, {}) },
        { path: "/login", element: _jsx(Login, {}) },
    ];
    const authenticatedRoutes = [
        {
            path: "/",
            element: isAuthenticated ? _jsx(Outlet, {}) : _jsx(Navigate, { to: "/login", replace: true }),
            children: [
                { path: "dashboard", element: _jsx(Dashboard, {}) },
                { path: "users", element: _jsx(Users, {}) },
                { path: "user/:nif", element: _jsx(UserDetails, {}) },
            ],
        },
        { path: "*", element: _jsx(Navigate, { to: "/dashboard", replace: true }) },
    ];
    const router = createBrowserRouter([...publicRoutes, ...authenticatedRoutes]);
    return _jsx(RouterProvider, { router: router });
};
export default AppRoutes;
